.notSortable {
    cursor: default !important;
    background: rgba(0, 0, 0, .02) !important;
    //pointer-events: none !important;    
}

.tableHolder {
    overflow: auto;

    // &::-webkit-scrollbar {
    //     display: none;
    // }
}

.tableHolder.scrollTable  thead {
    top: 0;
}

.tableHolder thead {
    position: sticky;
    z-index: 1;
    background-color: white;
}

.tableHolder td .ui.button {
    padding: .4rem !important;
    font-size: .78571429rem;
}

//for acionts menu hacks to fit inside table and not overflow
.tableHolder .ui.upward.dropdown>.menu {
    left: 110%;
    bottom: 0%;
    border-radius: .28571429rem .28571429rem;
}

.tableHolder .ui.dropdown .menu>.item {
    padding: 6px 10px !important;
}

.tableHolder .ui.upward.dropdown.button:not(.pointing):not(.floating).active {
    border-radius: .28571429rem .28571429rem;
}