#root,
.App {
  height: 100%;
  flex-direction: column;
  display: flex;
}

#root {
  background-color: #EFEFEF;
}

.mainContent {
  margin-left: 200px;
  padding: 54px 20px 20px 20px;
  box-sizing: border-box;
  transition: .5s margin;
  background-color: #EFEFEF;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &.menuClosed {
    margin-left: 30px;
  }
}

.mainContent:has(.scrollTable):not(:has(.tabs)) {
  height: 100%;
}

.dimmable {
  display: flex;
  flex-direction: column;
}

.dimmable:has(.scrollTable) {
  height: 100%;
  overflow: hidden;
}

.tabs .dimmable {
  height: auto;
}

.errorModal {
  margin-top: auto !important;
  display: inline-block !important;
  position: relative;
  top: 30%;
}

.react-datepicker-popper {
  z-index: 10 !important;
}

.ui.search > .results,
.ui.dropdown .menu {
  overflow-y: auto;
  max-height: 300px;
}

iframe {
  border: 0;
  width: 100%;
}